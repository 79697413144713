<template>
  <div class="PriceFacet">
    <div class="mt-2 grid grid-cols-2 gap-x-10 justify-between -ml-2 -mr-2">
      <div>
        <span class="text-system-gray text-xs">{{ $t('from') }}</span>
        <div class="flex items-center text-md font-bold">
          <input
            :value="range[0]"
            type="number"
            :max="max"
            min="0"
            class="font-bold text-sm w-full rounded-lg px-2 border py-1"
            @change="updatePriceRange(0, $event as InputEvent)"
          />
          <span class="ml-2 text-sm text-primary-1-100">{{ currencyCode }}</span>
        </div>
      </div>

      <div>
        <span class="text-system-gray text-xs">{{ $t('to') }}</span>
        <div class="flex items-center text-md font-bold">
          <input
            :value="range[1]"
            type="number"
            class="font-bold text-sm w-full rounded-lg px-2 border py-1"
            :max="max"
            min="1"
            @change="updatePriceRange(1, $event as InputEvent)"
          />
          <span class="ml-2 text-sm text-primary-1-100">{{ currencyCode }}</span>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <VueSlider
        v-model="range"
        :min="options[0] ?? 0"
        :max="options[1] ?? 0"
        :direction="$i18n.locale === 'en' ? 'ltr' : 'rtl'"
      />
    </div>

    <div v-if="lazy" class="mt-4 grid grid-cols-3 gap-x-3">
      <button class="py-2 border-2 border-primary-1-100 text-primary-1-100" @click="cancel">{{ $t('cancel') }}</button>
      <button class="py-2 bg-primary-1-100 text-white col-span-2" @click="apply">{{ $t('apply') }}</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { debounce, uniq } from 'lodash-es';
// Lazy load it because it requires access to `document` which
// breaks SSR, so we want to avoid rendering it until the client-side has loaded
// Async components does that for us!
const VueSlider = defineAsyncComponent(() => import('vue-slider-component'));

const props = defineProps<{
  value: [number, number];
  options: [number, number];
  lazy?: boolean;
}>();

const emit = defineEmits(['input', 'close']);

// to protect the slider just remove the duplicated props values
const uniqueValue = uniq(props.value);
const max = props.options[1];
const range = ref<[number, number]>(uniqueValue?.length ? ([...uniqueValue] as [number, number]) : [0, 0]);

const currencyCode = inject(CONFIG_STORE_CURRENCY);

function updatePriceRange(valueIdx: 0 | 1, e: InputEvent) {
  const inputValue = Number((e.target as any).value);
  const maxInputValue = Math.max(1, Math.min(inputValue, max));
  if (Number.isNaN(maxInputValue)) {
    return;
  }

  const newValue: [number, number] = [...range.value];
  newValue[valueIdx] = maxInputValue;
  range.value = newValue;
}

watch(range, newRange => {
  if (range.value[0] > range.value[1]) {
    [newRange[0], newRange[1]] = [newRange[1], newRange[0]];
  }
});

const { t: $t } = useI18n({
  useScope: 'local',
});

if (!props.lazy) {
  watch(range, debounce(apply, 300));
}

function apply() {
  emit('input', range.value);
  emit('close');
}

function cancel() {
  range.value = [...props.value] as typeof range.value;
  emit('close');
}
</script>

<style lang="postcss" scoped>
.PriceFacet {
  @apply flex flex-col  py-3  pb-4;

  /* https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors */
  :deep() {
    .vue-slider-dot {
      @apply w-5 h-5 rounded-full bg-primary-1-100;
    }
    .vue-slider-dot-handle {
      @apply bg-primary-1-100;
    }

    .vue-slider-process {
      @apply bg-primary-1-100;
    }

    .vue-slider-dot-tooltip-inner-top {
      @apply font-bold -mb-2;
    }

    .vue-slider-rail {
      @apply bg-primary-1-40;
      height: 6px;
    }
  }

  input {
    @apply text-primary-1-100;
    appearance: textfield;
    min-height: 100%;
    text-align: right;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__Currency {
    /* Fits at least 3 characters */
    min-width: 3em;
  }
}
</style>

<i18n>
{
  "en": {
    "cancel": "Cancel",
    "apply": "Apply",
    "from": "From",
    "to": "To"
  },
  "ar": {
    "cancel": "اغلاق",
    "apply": "اختيار",
    "from": "من",
    "to": "الى"
  }
}
</i18n>
